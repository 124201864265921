import React, {useEffect, useMemo, useRef, useState} from 'react';
import './GalleryPane.css';
import {grid_url} from './config.json'
import {SubSerie} from "./subserie";

export const GalleryPaneGrid4 = ({images, gridSettings, subSeries}) => {
    const ref = useRef(null)
    const [selectedSerie, setSelectedSerie] = useState(undefined)
    const style = useMemo(() => {
        return {...gridSettings, display: 'flex', flexDirection: 'column'}
    }, [gridSettings])

    console.log('subSerie', subSeries)

    const subSerieSelectedPhoto = useMemo(() => {
        if (!selectedSerie) {
            return null
        }
        return subSeries[selectedSerie - 1]
    }, [selectedSerie])

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollIntoView()
        }
    }, [ref.current, selectedSerie])

    if (!images) return null

    return (
        <>
            <div className="gallery-pane4">
                <div className="gallery-pane__left_up" style={{style}}>
                    <div className="gallery-pane_dubble_diff_upLeft">
                        <img src={grid_url + images[0].img} onClick={() => {
                            setSelectedSerie(1)
                        }}/>
                        <img src={grid_url + images[1].img} onClick={() => {
                            setSelectedSerie(2)
                        }}/>
                    </div>
                </div>
                <div className="gallery-pane__right_up" onClick={() => {
                    setSelectedSerie(3)
                }}>
                    <img src={grid_url + images[2].img}/>
                </div>
                <div className="gallery-pane__right_down">
                    <div className="gallery-pane_dubble_diff">
                        <img src={grid_url + images[4].img} onClick={() => {
                            setSelectedSerie(5)
                        }}/>
                        <img src={grid_url + images[5].img} onClick={() => {
                            setSelectedSerie(6)
                        }}/>
                    </div>

                </div>
                <div className="gallery-pane__left_down" onClick={() => {
                    setSelectedSerie(4)
                }}>
                    <img src={grid_url + images[3].img}/>
                </div>
            </div>
            <div ref={ref}>
                <SubSerie
                    setSelectedSerie={setSelectedSerie}
                    selectedSerie={selectedSerie}
                    subSerieSelectedPhoto={subSerieSelectedPhoto}
                />
            </div>
        </>
    );
};
