import React, {useState, useRef} from 'react';
import {BrowserRouter as Router, useHistory, Link} from "react-router-dom";
import logoInsta from './images/instagram_logo.png'

const Header = () => {
    const history = useHistory();
    const ref = useRef();
    const [displayMenu, setDisplayMenu] =  useState(false);

    const changeUrl = (url) => {
        history.push(url);
        setDisplayMenu(false)
        ref.current.checked = false
    }

    return (
        <header className="App-header">
            <div className="tools">
                <div id="menuToggle"  onClick={ () => {setDisplayMenu(prev => !prev) }} >
                    <input type="checkbox" ref={ref} />
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <span>
                  <a href="https://www.instagram.com/valerie_van_der_werff/?hl=nl" target="_blank" >
                      <img className="icon"
                           src={logoInsta}
                           alt="logo of instagram"
                           title="Go to my Instagram page"
                      />
                  </a>
              </span>
            </div>
            <Link to={'/'}>
                <div className="App-header__logo">
                </div>
            </Link>
            <div className={"menu " + (displayMenu ? 'show' : 'hidden')}>
                <ul>
                    <li onClick={ () => { changeUrl('/') } }>home</li>
                    <li onClick={ () => { changeUrl('/about') } }>about</li>
                    <li onClick={ () => { changeUrl('/contact') } }>contact</li>
                </ul>
            </div>
        </header>
    );
};

export default Header;
