import React from 'react';
import './contact.css'

const Contact = () => {
    return (
        <div className="contact">
            <div className="contact__title">
                <h4>VALERIE Creative Agency</h4>

                <div className="contact__address">
                    <div>Address office:</div>
                    <div>Suite 244 - Hotel de L’Europe</div>
                    <div>Nieuwe Doelenstraat 2-14</div>
                    <div>1012 CP Amsterdam</div>
                    <div>The Netherlands</div>
                    <br/>
                    <div>Mail</div>
                    <div><a href="mailto:style@valerievanderwerff.com">style@valerievanderwerff.com</a></div>
                    <br/>
                    <div>mob</div>
                    <div><a href="tel:+31628769023">(+31) (0) 6 28769023</a></div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
