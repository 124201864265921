import React, {useEffect, useMemo, useRef, useState} from 'react'
import {grid_url} from "./config.json"
import {SubSerie} from "./subserie";

export const GalleryPaneGrid1 = ({images, gridSettings, subSeries}) => {
    const ref = useRef(null)

    const [selectedSerie, setSelectedSerie] = useState(undefined)

    const style = useMemo(() => {
        return {...gridSettings, display: 'flex', flexDirection: 'column'}
    }, [gridSettings])

    const subSerieSelectedPhoto = useMemo(() => {
        if (!selectedSerie) {
            return null
        }
        return subSeries[selectedSerie-1]
    }, [selectedSerie])

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollIntoView()
        }
    }, [ref.current, selectedSerie])

    if (!images) return null

    return (
        <>
            <div className="gallery-pane6">
                <div className="gallery-pane__left_one" style={{style}} onClick={() => {setSelectedSerie(1)}}>
                    <img src={grid_url + images[0].img}/>
                </div>
                <div className="gallery-pane__right_up_two" style={{style}} onClick={() => {setSelectedSerie(2)}}>
                    <img src={grid_url + images[1].img}/>
                </div>
                <div className="gallery-pane__right_three_down">
                    <div className="gallery-pane_dubble_max">
                        <img src={grid_url + images[2].img} onClick={() => {setSelectedSerie(3)}}/>
                        <img src={grid_url + images[3].img} onClick={() => {setSelectedSerie(4)}}/>
                    </div>
                </div>
                <div className="gallery-pane__left_two_bottom" onClick={() => {setSelectedSerie(5)}}>
                    <img src={grid_url + images[4].img}/>
                </div>
            </div>
            <div ref={ref}>
                <SubSerie
                    setSelectedSerie={setSelectedSerie}
                    selectedSerie={selectedSerie}
                    subSerieSelectedPhoto={subSerieSelectedPhoto}
                />
            </div>
        </>
    );
};
