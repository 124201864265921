import React, {useEffect, useMemo, useRef, useState} from 'react';
import './GalleryPane.css';
import {grid_url} from './config.json'
import {SubSerie} from "./subserie";

export const GalleryPaneGrid3 = ({images, gridSettings, subSeries}) => {
    const [selectedSerie, setSelectedSerie] = useState(undefined);
    const ref = useRef(null)

    const style = useMemo(() => {
        return {...gridSettings, display: 'flex', flexDirection: 'column'}
    }, [gridSettings])

    const subSerieSelectedPhoto = useMemo(() => {
        if (!selectedSerie) {
            return null
        }

        return subSeries[selectedSerie - 1]
    }, [selectedSerie])

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollIntoView()
        }
    }, [])

    if (!images) return null

    return (
        <>
            <div className="gallery-pane" style={{style}}>
                <div className="gallery-pane__left_two" onClick={() => {setSelectedSerie(1)}}>
                    <img src={grid_url+images[0].img} />
                </div>
                <div className="gallery-pane__right_up" onClick={() => {setSelectedSerie(2)}}>
                    <img src={grid_url+images[1].img} />
                </div>

                <div className={"gallery-pane__right_down"}>
                    <div className={"gallery-pane_dubble_max"}>
                        <img src={grid_url+images[2].img} onClick={() => {setSelectedSerie(3)}}/>
                        <img src={grid_url+images[3].img} onClick={() => {setSelectedSerie(4)}}/>
                    </div>
                </div>
            </div>
            <div ref={ref}>
                <SubSerie
                    setSelectedSerie={setSelectedSerie}
                    selectedSerie={selectedSerie}
                    subSerieSelectedPhoto={subSerieSelectedPhoto}
                />
            </div>
        </>
    );
};

/*
        <>
        <div className="gallery-pane64">
            <div className="gallery-pane__left_up_one">
                <img src={'./images/'+images[2].img} />
            </div>
            <div className="gallery-pane__right_up_one">
                <img src={'./images/'+images[1].img} />
            </div>

            <div className="gallery-pane__left_middle_one">
                <img src={'./images/'+images[0].img} />
            </div>
            <div className="gallery-pane__right_middle_one">
                <img src={'./images/'+images[3].img} />
            </div>
            <div className="gallery-pane__left_down_six">
                <img src={'./images/'+images[4].img} />
            </div>
            <div className="gallery-pane__right_down_six">
                <img src={'./images/'+images[5].img} />
            </div>
        </div>
        </>
 */
