import React, {useEffect, useMemo, useRef, useState} from 'react';
import './GalleryPane.css';
import {grid_url} from './config.json'
import {SubSerie} from "./subserie";

export const GalleryPaneGrid2 = ({images, gridSettings, subSeries}) => {
    const [selectedSerie, setSelectedSerie] = useState(undefined);
    const ref = useRef(null)

    const style = useMemo(() => {
        return {...gridSettings, display: 'flex', flexDirection: 'column'}
    }, [gridSettings])

    const subSerieSelectedPhoto = useMemo(() => {
        if (!selectedSerie) {
            return null
        }

        return subSeries[selectedSerie - 1]
    }, [selectedSerie])

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollIntoView()
        }
    }, [])

    if (!images) return null

    return (
        <>
            <div className="gallery-pane2">
                <div className="gallery-pane_grid2_left_up" style={{style}}>
                    <div className="gallery-pane_dubble_diff">
                        <img src={grid_url + images[0].img} onClick={() => {setSelectedSerie(1)}}/>
                        <img src={grid_url + images[1].img} onClick={() => {setSelectedSerie(2)}}/>
                    </div>
                </div>
                <div className="gallery-pane_grid2_right_up" onClick={() => {setSelectedSerie(3)}}>
                    <img src={grid_url + images[2].img}/>
                </div>
                <div className="gallery-pane_grid2_right_bottom" onClick={() => {setSelectedSerie(4)}}>
                    <img src={grid_url + images[3].img}/>
                </div>
                <div className="gallery-pane_grid2_left_bottom" onClick={() => {setSelectedSerie(5)}}>
                    <img src={grid_url + images[4].img}/>
                </div>
            </div>
            <div ref={ref}>
                <SubSerie
                    setSelectedSerie={setSelectedSerie}
                    selectedSerie={selectedSerie}
                    subSerieSelectedPhoto={subSerieSelectedPhoto}
                />
            </div>
        </>
    );
};
