import {useState} from 'react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import './App.css';
import {GalleryPaneGrid1} from "./GalleryPaneGrid1"
import {GalleryPaneGrid2} from "./GalleryPaneGrid2"
import {GalleryPaneGrid3} from "./GalleryPaneGrid3"
import {GalleryPaneGrid4} from "./GalleryPaneGrid4"

import Header from './Header'
import About from './pages/about'
import Contact from './pages/contact'
import {gallery} from './config.json'

export const App = () => {
    const [gridSettings, setGridSettings] = useState();
    const [displaySerie, setDisplaySerie] = useState(-1);

    const expand = (number) => {
        if (displaySerie !== -1) {
            setDisplaySerie(-1)
        } else {
            setDisplaySerie(number)
        }

        if (number === -1) {
            setGridSettings({
                'gridColumn': '1',
                'gridRow': '1',
            })
        } else {
            setGridSettings({
                'gridColumn': '1 /span 2',
                'gridRow': '1 / span 3',
                'zIndex': 998,
            })
        }
    }

    const getGrid = (grid) => {
        switch (grid.type) {
            // Five images
            case 'grid1': {
                return <GalleryPaneGrid1
                    images={grid.photos}
                    gridSettings={gridSettings}
                    subSeries={grid.subSeries}
                />
            }
            // small left top, small right bottom
            case 'grid2': {
                return <GalleryPaneGrid2
                    images={grid.photos}
                    gridSettings={gridSettings}
                    subSeries={grid.subSeries}
                />
            }
            case 'grid3': {
                return <GalleryPaneGrid3
                    images={grid.photos}
                    gridSettings={gridSettings}
                    subSeries={grid.subSeries}
                />
            }
            // Four images
            case 'grid4': {
                return <GalleryPaneGrid4
                    series={grid.series} number={grid.number} expand={expand}
                    subSeries={grid.subSeries}
                    displaySerie={displaySerie} gridSettings={gridSettings} images={grid.photos}/>
            }
            default:
                return null
        }
    }

    return (
        <div className="App">
            <Router basename={'/'}>
                <Header/>
                <Switch>
                    <Route exact path="/">
                        <main>
                            {gallery?.map((grid) => {
                                return getGrid(grid)
                            })
                            }
                        </main>
                    </Route>
                    <Route path={`/about`}>
                        <About/>
                    </Route>
                    <Route path={`/contact`}>
                        <Contact/>
                    </Route>
                </Switch>
            </Router>
        </div>
    );
}
