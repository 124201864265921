import React from 'react';
import {subserie_url} from "./config.json";

export const SubSerie = ({selectedSerie, setSelectedSerie, subSerieSelectedPhoto}) => {
    if(!subSerieSelectedPhoto) { return null }

    return (
        <>
            {(selectedSerie) && (
                <div onClick={() => {
                    setSelectedSerie(-1)
                }}>
                    <div className="title-and-credit">
                        <p>{subSerieSelectedPhoto?.title} {subSerieSelectedPhoto?.credits}</p>
                    </div>
                    <div className="serie">
                        <div>
                            {subSerieSelectedPhoto.photos?.map(img => <img src={`${subserie_url}${img.serie}/${img.img}`}/>)}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
