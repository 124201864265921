import React from 'react';
import './about.css'

const About = () => {
    return (
        <div className="about">
            <div className="about__photo"></div>
            <div className="about__info">
                <div>
                    <h4>VALERIE CREATIVE AGENCY</h4>
                    <div className="about__me">
                        <p>VALERIE Creative Agency creates interior & lifestyle stories for printing or online. From a first concept to a final story, digital or ready for print. We work with talented (fashion) photographers and designers. Looking for the best locations, high end models, talents & hair/make-up artists, we produce lifestyle stories with a mix of interior stills, landscapes and portraits of people at home or in their own habitat. The nonchalance and real life spirit of the images is what we aim the most.</p>
                        <p>Valerie van der Werff works as a freelance interior photo stylist since 2000 after graduating Publishing School at the Frederik Muller Academie in Amsterdam. Her portfolio is a collection of Dutch and international magazines editorials, advertorial and campaigns. She made campaigns for several clients as Dedon, Linteloo, RR Interieur Philips Lumea, Dutchbone and Gimber Drink.</p>
                        <p>In spring 2014 she started as lifestyle director of VOGUE Living NL, a newborn title in the Dutch & Belgium market with 6 issues a year. VOGUE Living is the 2nd Condé Nast VOGUE Living title in the world, next to the famous Australian VOGUE Living. Valerie was responsible for the coordination of the content and especially the recognizable new view for interior and lifestyle photography.</p>
                        <p>Autumn ‘21 she launched her business VALERIE Creative Agency in the centre of Amsterdam. Where the creative spirit of writers and designers come together and cooperate in lifestyle brand campaigns for print and online.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
